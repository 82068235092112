import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import favicon from '../../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";

import SkannerCarousel from "../../components/carousel/skanner_carousel";
import { CarouselSingleItem } from "../../components/carousel/carousel_item";

// images
import banner_img from "../../images/undersidor/skanner.svg";

import extern_skanning from "../../images/undersidor/extern_skanning.jpg";

import skanner_1 from '../../images/skanner/skanner_1.png';
import skanner_2 from '../../images/skanner/skanner_2.png';
import skanner_3 from '../../images/skanner/skanner_3.png';

//imports for logo images
import avision from '../../images/skanner/avision.jpg';
import book2net from '../../images/skanner/book2net.jpg';
import fijitsu from '../../images/skanner/fijitsu.jpg';
import inco_tec from '../../images/skanner/inco_tec.jpg';
import janich_klass from '../../images/skanner/janich_klass.jpg';
import kodak from '../../images/skanner/kodak.jpg';
import scanoptics from '../../images/skanner/scanoptics.jpg';


const KONTAKT_BOT = "kxFV5wCG";

function Skanning() {
    const [kontakt_1, set_kontakt_1] = useState(false);
    const [kontakt_2, set_kontakt_2] = useState(false);
    const [kontakt_3, set_kontakt_3] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Scanning | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Internal or external Scanning of invoices and documents" />
                <meta name="keywords" content="Invoice Scan | Invoice interpretation | supplier invoices" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Internal or external Scanning of invoices and documents" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="en_EN" />
                <meta property="og:url" content="https://www.crediflow.com/supplier-invoices/scanning" />
                <link rel="cannonical" href="https://www.crediflow.com/supplier-invoices/scanning" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">Scanning of paper invoices</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        Do you want to receive and scan your paper invoices yourself or do you want them scanned?
                                        <br/><br/>With us, you choose yourself.
                                    </p>
                                    <button onClick={() => set_kontakt_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Get in contact</button>
                                    {kontakt_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={KONTAKT_BOT} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 sm:1/3 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="Scanning of paper invoices" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Home</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <Link to="/supplier-invoices/" className="flex items-center text-blue-800 hover:text-blue-600">supplier-invoices</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">scanning</span>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 pt-12" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">Custom scanning</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                If you wish to scan your invoices yourself, we strongly 
                                recommend our API integrated scanner solution. When you 
                                scan your invoices on this scanner, the images come 
                                directly to our CrossState eVoyce service, 
                                unlike if you scan on a copier.
                                <br/><br/>
                                You rent this scanner as long as you use our service 
                                and include a service agreement that guarantees that it works.
                                <br/><br/>
                                Since we have our own service staff who are certified on 
                                the scanners we sell, you as a customer are guaranteed the best service.
                                <br/><br/>
                                Of course, we also sell other brands and 
                                models, contact us to see what suits you best!
                            </p>
                            <button onClick={() => set_kontakt_2(true)} className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Get in contact</button>
                            
                            {kontakt_2 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={KONTAKT_BOT} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>
                
                        <div className="w-full p-4 mx-auto">
                            <SkannerCarousel> 
                                <CarouselSingleItem image_url={skanner_1}/>
                                <CarouselSingleItem image_url={skanner_2}/>
                                <CarouselSingleItem image_url={skanner_3}/>
                            </SkannerCarousel>
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 pt-28 pb-40" id="skickaFaktura_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Our other range of professional scanners</h1>
                    <p className="px-4 mt-4 w-full md:w-1/2 xl:w-1/3 mx-auto text-center text-gray-600">We offer service and support on the following brands</p>
                    <div className="mt-10 grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-7 gap-8">
                        <div className="w-full"><img className="w-full rounded-lg shadow-md" src={avision} alt="avision"/></div>
                        <div className="w-full"><img className="w-full rounded-lg shadow-md" src={book2net} alt="book2net"/></div>
                        <div className="w-full"><img className="w-full rounded-lg shadow-md" src={fijitsu} alt="fijitsu"/></div>
                        <div className="w-full"><img className="w-full rounded-lg shadow-md" src={inco_tec} alt="inco tec"/></div>
                        <div className="w-full"><img className="w-full rounded-lg shadow-md" src={janich_klass} alt="janich klass"/></div>
                        <div className="w-full"><img className="w-full rounded-lg shadow-md" src={kodak} alt="kodak"/></div>
                        <div className="w-full"><img className="w-full rounded-lg shadow-md" src={scanoptics} alt="scanoptics"/></div>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 py-40" id="skickaFaktura_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100 pb-32 " >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-start">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">External scanning</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                If you instead want us to scan your invoices/documents, 
                                that's just as well. You then receive a new invoice 
                                mailing address that you give to your suppliers. When 
                                your mail arrives, we open it and sort as well as 
                                scan and store your invoices according to legal requirements.
                            </p>
                            <button onClick={() => set_kontakt_3(true)} className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Get in contact</button>
                            {kontakt_3 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={KONTAKT_BOT} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>
                        <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="rounded-lg shadow-md w-full" src={extern_skanning} alt="External scanning" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Skanning;